import SearchBar from '@/common/components/SearchBar';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { Maybe } from '@/common/types';
import { AssetMultiLayerFilter } from '@/modules/assets/components/AssetMultiLayerFilter';
import PartCardList from '@/modules/parts/components/PartCardList';
import { usePartDetailModal } from '@/modules/parts/components/PartDetailModal';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import {
  Box,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  useModalContext,
} from '@chakra-ui/react';
import { type FC, useState } from 'react';
import {
  useCountPartsModalQuery,
  usePartsModalQuery,
} from './WorkOrderPartFieldSelectModalContent.generated';

const initialOffset = 0;
const pageSize = 30;
const titleProps = {
  fontSize: 'sm',
  color: 'neutral.900',
};

gql`
  query PartsModal($searchField: String, $assetIds: [Int!], $offset: Int, $limit: Int!) {
    parts(searchField: $searchField, assetIds: $assetIds, offset: $offset, limit: $limit) {
      ...PartCardList_Parts
    }
  }
`;

gql`
  query CountPartsModal($searchField: String, $assetIds: [Int!]) {
    countParts(searchField: $searchField, assetIds: $assetIds)
  }
`;

export type WorkOrderPartFieldSelectModalProps = {
  isEdit?: boolean;
  workOrderAssetId: Maybe<number>;
  onPartClicked: (partId: number, stock: number) => void;
};

const WorkOrderPartFieldSelectModalContent: FC<WorkOrderPartFieldSelectModalProps> = (
  props: WorkOrderPartFieldSelectModalProps
) => {
  const { workOrderAssetId, onPartClicked } = props;
  const { t } = useTranslation();
  const modalContext = useModalContext();
  // initialFetchは、関連設備がない場合に、全ての設備を選択するためのフラグ
  const [initialFetch, setInitialFetch] = useState(true);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedAssetIds, setSelectedAssetIds] = useState<number[] | undefined>(
    workOrderAssetId ? [workOrderAssetId] : undefined
  );

  const isRelatedParts =
    selectedAssetIds && selectedAssetIds.length === 1 && selectedAssetIds[0] === workOrderAssetId;

  const { data, fetchMore } = usePartsModalQuery({
    variables: {
      offset: initialOffset,
      limit: pageSize,
      assetIds: selectedAssetIds,
      searchField: searchText,
    },
  });
  const { data: countPartsData } = useCountPartsModalQuery({
    variables: {
      assetIds: selectedAssetIds,
      searchField: searchText,
    },
    onCompleted: (data) => {
      if (initialFetch) {
        if (data.countParts === 0 && workOrderAssetId) {
          setSelectedAssetIds(undefined);
        }
        setInitialFetch(false);
      }
    },
  });

  const onLoadMoreClicked = () => {
    fetchMore({
      variables: {
        offset: data?.parts?.length ?? 0,
      },
    });
  };

  const onAssetFilterChange = (assetIds?: number[]) => {
    // 空配列の場合は、前設備で検索したいので、undefinedに変換
    setSelectedAssetIds(assetIds);
  };

  const onSearch = (searchText: string) => {
    setSearchText(searchText);
  };

  const { element: partDetailModal, open: openPartDetailModal } = usePartDetailModal();

  return (
    <ModalContent>
      {partDetailModal}
      <ModalHeader>
        <HStack spacing='12px' pr={5}>
          <SearchBar
            value={searchText}
            setValue={onSearch}
            onSearch={onSearch}
            sx={{ h: '100%', w: '90%' }}
          />
          <Box>
            <SuspenseWithSpinner>
              <AssetMultiLayerFilter values={selectedAssetIds} onChange={onAssetFilterChange} />
            </SuspenseWithSpinner>
          </Box>
        </HStack>
        <ModalCloseButton size='sm' />
      </ModalHeader>
      <ModalBody p={1} borderTop='1px' borderColor='neutral.300'>
        {data && data.parts && (
          <PartCardList
            parts={data.parts}
            totalCount={countPartsData?.countParts || 0}
            title={isRelatedParts ? t('part.related-parts') : undefined}
            onPartClicked={(partId) => {
              const part = data.parts.find((part) => part.id === partId);
              onPartClicked(partId, part?.stock || 0);
              modalContext.onClose();
            }}
            titleProps={titleProps}
            onPartDetailClicked={(partId) => openPartDetailModal({ partId })}
            onLoadMoreClicked={onLoadMoreClicked}
          />
        )}
      </ModalBody>
    </ModalContent>
  );
};

export default WorkOrderPartFieldSelectModalContent;
